ER.require('ER.Erdx.Homepage', function(){
  var that = {};

  var init = function(options){
    var loginForm = $('.js-loginForm');

    loginForm.submit(function (e) {
      e.preventDefault();

      ER.Erdx.Layout.showSpinner();

      $.ajax({
        url: options.routes.login_path,
        type: 'post',
        data: loginForm.serialize(true),
        success: function(){
          ER.Erdx.Layout.hideSpinner();
          window.location.reload();  
        },
        error: function(){
          ER.Erdx.Layout.hideSpinner();
          alert('Invalid email or password!');
        }
      });
    });
  };

  that.init = init;
  return that;
}());

$('.js-inventory-slider').sly({
  horizontal: 1,
  itemNav: 'basic',
  speed: 300,
  mouseDragging: 1,
  touchDragging: 1,
  startAt: null,
  activatePageOn: 'click',
  activateMiddle: false,
  smart: 1,
  pagesBar: $('.pages'),
});
